/*
 * @Auth: linjituan
 * @Date: 2021-12-23 17:12:24
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-23 20:02:51
 */
import request from '@/utils/request'
const baseURL = process.env.VUE_APP_JT_API

// 查询车辆每日报表列表
export function listVehicleDailyReport(query) {
  return request({
    url: '/vehicleDailyReport/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询车辆每日报表详细
export function getVehicleDailyReport(id) {
  return request({
    url: '/vehicleDailyReport/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增车辆每日报表
export function addVehicleDailyReport(data) {
  return request({
    url: '/vehicleDailyReport',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改车辆每日报表
export function updateVehicleDailyReport(data) {
  return request({
    url: '/vehicleDailyReport',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除车辆每日报表
export function delVehicleDailyReport(id) {
  return request({
    url: '/vehicleDailyReport/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出车辆每日报表
export function exportVehicleDailyReport(query) {
  return request({
    url: '/vehicleDailyReport/export',
    baseURL,
    method: 'get',
    params: query
  })
}
