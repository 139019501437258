var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "设备编号", prop: "deviceId" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", placeholder: "请输入设备编号" },
                    model: {
                      value: _vm.form.deviceId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deviceId", $$v)
                      },
                      expression: "form.deviceId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "车辆编号", prop: "vehicleNo" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", placeholder: "请输入车辆编号" },
                    model: {
                      value: _vm.form.vehicleNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "vehicleNo", $$v)
                      },
                      expression: "form.vehicleNo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "车牌号", prop: "plateNo" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", placeholder: "请输入车牌号" },
                    model: {
                      value: _vm.form.plateNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "plateNo", $$v)
                      },
                      expression: "form.plateNo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "日期", prop: "reportDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.reportDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "reportDate", $$v)
                  },
                  expression: "form.reportDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "时长(秒)", prop: "timeSum" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入时长(秒)" },
                model: {
                  value: _vm.form.timeSum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "timeSum", $$v)
                  },
                  expression: "form.timeSum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "里程(公里)", prop: "odoSum" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入里程(公里)" },
                model: {
                  value: _vm.form.odoSum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "odoSum", $$v)
                  },
                  expression: "form.odoSum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "点火次数", prop: "ignitionCount" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入点火次数" },
                model: {
                  value: _vm.form.ignitionCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ignitionCount", $$v)
                  },
                  expression: "form.ignitionCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "最早点火时间", prop: "ignitionFirstTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.ignitionFirstTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ignitionFirstTime", $$v)
                  },
                  expression: "form.ignitionFirstTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "最晚点火时间", prop: "ignitionLastTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.ignitionLastTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ignitionLastTime", $$v)
                  },
                  expression: "form.ignitionLastTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "最早点火GPS经度",
                prop: "ignitionFirstLongitude",
              },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入最早点火GPS经度" },
                model: {
                  value: _vm.form.ignitionFirstLongitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ignitionFirstLongitude", $$v)
                  },
                  expression: "form.ignitionFirstLongitude",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "最早点火GPS纬度",
                prop: "ignitionFirstLatitude",
              },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入最早点火GPS纬度" },
                model: {
                  value: _vm.form.ignitionFirstLatitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ignitionFirstLatitude", $$v)
                  },
                  expression: "form.ignitionFirstLatitude",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "最晚点火GPS经度",
                prop: "ignitionLastLongitude",
              },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入最晚点火GPS经度" },
                model: {
                  value: _vm.form.ignitionLastLongitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ignitionLastLongitude", $$v)
                  },
                  expression: "form.ignitionLastLongitude",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "最晚点火GPS纬度", prop: "ignitionLastLatitude" },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入最晚点火GPS纬度" },
                model: {
                  value: _vm.form.ignitionLastLatitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ignitionLastLatitude", $$v)
                  },
                  expression: "form.ignitionLastLatitude",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "熄火次数", prop: "flameoutCount" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入熄火次数" },
                model: {
                  value: _vm.form.flameoutCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "flameoutCount", $$v)
                  },
                  expression: "form.flameoutCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "最早熄火时间", prop: "flameoutFirstTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.flameoutFirstTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "flameoutFirstTime", $$v)
                  },
                  expression: "form.flameoutFirstTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "最晚熄火时间", prop: "flameoutLastTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.flameoutLastTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "flameoutLastTime", $$v)
                  },
                  expression: "form.flameoutLastTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "最早熄火GPS经度",
                prop: "flameoutFirstLongitude",
              },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入最早熄火GPS经度" },
                model: {
                  value: _vm.form.flameoutFirstLongitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "flameoutFirstLongitude", $$v)
                  },
                  expression: "form.flameoutFirstLongitude",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "最早熄火GPS纬度",
                prop: "flameoutFirstLatitude",
              },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入最早熄火GPS纬度" },
                model: {
                  value: _vm.form.flameoutFirstLatitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "flameoutFirstLatitude", $$v)
                  },
                  expression: "form.flameoutFirstLatitude",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "最晚熄火GPS经度",
                prop: "flameoutLastLongitude",
              },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入最晚熄火GPS经度" },
                model: {
                  value: _vm.form.flameoutLastLongitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "flameoutLastLongitude", $$v)
                  },
                  expression: "form.flameoutLastLongitude",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "最晚熄火GPS纬度", prop: "flameoutLastLatitude" },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入最晚熄火GPS纬度" },
                model: {
                  value: _vm.form.flameoutLastLatitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "flameoutLastLatitude", $$v)
                  },
                  expression: "form.flameoutLastLatitude",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }