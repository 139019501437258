<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="设备编号" prop="deviceId" v-if="formType === 1">
        <a-input disabled v-model="form.deviceId" placeholder="请输入设备编号" />
      </a-form-model-item>
      <a-form-model-item label="车辆编号" prop="vehicleNo" v-if="formType === 1">
        <a-input disabled v-model="form.vehicleNo" placeholder="请输入车辆编号" />
      </a-form-model-item>
      <a-form-model-item label="车牌号" prop="plateNo" v-if="formType === 1">
        <a-input disabled v-model="form.plateNo" placeholder="请输入车牌号" />
      </a-form-model-item>
      <a-form-model-item label="日期" prop="reportDate">
        <a-date-picker
          style="width: 100%"
          disabled
          v-model="form.reportDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="时长(秒)" prop="timeSum">
        <a-input disabled v-model="form.timeSum" placeholder="请输入时长(秒)" />
      </a-form-model-item>
      <a-form-model-item label="里程(公里)" prop="odoSum">
        <a-input disabled v-model="form.odoSum" placeholder="请输入里程(公里)" />
      </a-form-model-item>
      <a-form-model-item label="点火次数" prop="ignitionCount">
        <a-input disabled v-model="form.ignitionCount" placeholder="请输入点火次数" />
      </a-form-model-item>
      <a-form-model-item label="最早点火时间" prop="ignitionFirstTime">
        <a-date-picker
          style="width: 100%"
          disabled
          v-model="form.ignitionFirstTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="最晚点火时间" prop="ignitionLastTime">
        <a-date-picker
          style="width: 100%"
          disabled
          v-model="form.ignitionLastTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="最早点火GPS经度" prop="ignitionFirstLongitude">
        <a-input disabled v-model="form.ignitionFirstLongitude" placeholder="请输入最早点火GPS经度" />
      </a-form-model-item>
      <a-form-model-item label="最早点火GPS纬度" prop="ignitionFirstLatitude">
        <a-input disabled v-model="form.ignitionFirstLatitude" placeholder="请输入最早点火GPS纬度" />
      </a-form-model-item>
      <a-form-model-item label="最晚点火GPS经度" prop="ignitionLastLongitude">
        <a-input disabled v-model="form.ignitionLastLongitude" placeholder="请输入最晚点火GPS经度" />
      </a-form-model-item>
      <a-form-model-item label="最晚点火GPS纬度" prop="ignitionLastLatitude">
        <a-input disabled v-model="form.ignitionLastLatitude" placeholder="请输入最晚点火GPS纬度" />
      </a-form-model-item>
      <a-form-model-item label="熄火次数" prop="flameoutCount">
        <a-input disabled v-model="form.flameoutCount" placeholder="请输入熄火次数" />
      </a-form-model-item>
      <a-form-model-item label="最早熄火时间" prop="flameoutFirstTime">
        <a-date-picker
          style="width: 100%"
          disabled
          v-model="form.flameoutFirstTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="最晚熄火时间" prop="flameoutLastTime">
        <a-date-picker
          style="width: 100%"
          disabled
          v-model="form.flameoutLastTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="最早熄火GPS经度" prop="flameoutFirstLongitude">
        <a-input disabled v-model="form.flameoutFirstLongitude" placeholder="请输入最早熄火GPS经度" />
      </a-form-model-item>
      <a-form-model-item label="最早熄火GPS纬度" prop="flameoutFirstLatitude">
        <a-input disabled v-model="form.flameoutFirstLatitude" placeholder="请输入最早熄火GPS纬度" />
      </a-form-model-item>
      <a-form-model-item label="最晚熄火GPS经度" prop="flameoutLastLongitude">
        <a-input disabled v-model="form.flameoutLastLongitude" placeholder="请输入最晚熄火GPS经度" />
      </a-form-model-item>
      <a-form-model-item label="最晚熄火GPS纬度" prop="flameoutLastLatitude">
        <a-input disabled v-model="form.flameoutLastLatitude" placeholder="请输入最晚熄火GPS纬度" />
      </a-form-model-item>
      <!-- <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div> -->
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleDailyReport, addVehicleDailyReport, updateVehicleDailyReport } from '@/api/jt808/vehicleDailyReport'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        deviceId: null,
        vehicleNo: null,
        plateNo: null,
        reportDate: null,
        timeSum: null,
        odoSum: null,
        ignitionCount: null,
        ignitionFirstTime: null,
        ignitionLastTime: null,
        ignitionFirstLongitude: null,
        ignitionFirstLatitude: null,
        ignitionLastLongitude: null,
        ignitionLastLatitude: null,
        flameoutCount: null,
        flameoutFirstTime: null,
        flameoutLastTime: null,
        flameoutFirstLongitude: null,
        flameoutFirstLatitude: null,
        flameoutLastLongitude: null,
        flameoutLastLatitude: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {}
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        deviceId: null,
        vehicleNo: null,
        plateNo: null,
        reportDate: null,
        timeSum: null,
        odoSum: null,
        ignitionCount: null,
        ignitionFirstTime: null,
        ignitionLastTime: null,
        ignitionFirstLongitude: null,
        ignitionFirstLatitude: null,
        ignitionLastLongitude: null,
        ignitionLastLatitude: null,
        flameoutCount: null,
        flameoutFirstTime: null,
        flameoutLastTime: null,
        flameoutFirstLongitude: null,
        flameoutFirstLatitude: null,
        flameoutLastLongitude: null,
        flameoutLastLatitude: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleDailyReport(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVehicleDailyReport(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicleDailyReport(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
